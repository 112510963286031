.custom.bit-lists {
  @apply text-white;
  padding: 10px 6px;
}

.custom.bit-event {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  border-top: 1px solid rgba(124, 124, 124, 0.25);
  margin-bottom: 5px;
}

.custom.bit-event:first-child {
  margin-top: -26px;
  border-top: 0px solid rgba(124, 124, 124, 0.25);
}

.custom .bit-date {
  min-width: 95px;
  margin: 0 15px 0 0;
  font-weight: bold;
}

.custom .bit-venue {
}

.custom .bit-location {
  font-weight: bold;
  margin: 0 15px 0 0;
}

.custom .bit-details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.custom .bit-details:hover .bit-location,
.custom .bit-details:hover .bit-date {
  color: #f35858;
}

.custom .bit-location {
  margin: 0;
}

.custom .bit-event-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.custom .bit-button {
  width: 100%;
  display: block;
  text-decoration: none;
  font-size: 0.88em;
  text-transform: uppercase;
  text-align: center;
  line-height: 44px;
  font-weight: bold;
}

.custom .bit-rsvp {
  background-color: transparent;
  border: 1px solid;
  @apply border-primary;
}

.custom .bit-offers {
  margin-left: 0;
  margin-top: 6px;
  @apply bg-primary text-white;
}

.bit-rsvp-container,
.bit-event-buttons,
.bit-offers-container.bit-single-cta {
  width: 100%;
}

@media (min-width: 890px) {
  .custom.bit-event {
    display: flex;
    flex-direction: row;
    margin-top: 0;
  }

  .custom .bit-event-buttons {
    flex-direction: row;
  }

  .custom .bit-offers {
    margin-top: 0;
    margin-left: 15px;
    width: 150px;
  }
  .custom .bit-details {
    flex-direction: row;
    align-items: center;
  }
  .custom .bit-location {
    margin-left: auto;
    margin-right: 15px;
  }

  .bit-rsvp.bit-button {
    width: 150px;
  }

  .bit-rsvp-container,
  .bit-event-buttons,
  .bit-offers-container.bit-single-cta {
    width: auto;
  }
}
