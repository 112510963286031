/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
  @apply transition-all;
}

/* Track */
::-webkit-scrollbar-track {
  @apply rounded-full;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary rounded-full;
}
