html {
  width: 100%;
  @apply overflow-x-hidden;
}

/* purgecss start ignore */
#root {
  min-height: 100vh;
}

.position-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.noselect {
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.touch-none {
  touch-action: none;
}

@tailwind utilities;
@variants responsive {
  .text-shadow {
    text-shadow: 2px 2px 4px black;
  }
  .text-shadow-md {
    text-shadow: 4px 4px 8px black;
  }
  .text-shadow-lg {
    text-shadow: 15px 15px 30px black;
  }
  .text-shadow-none {
    text-shadow: none;
  }
}

/* Navigation bar */
.nav-active {
  @apply text-primary relative;
}

.nav-active::before{
  content: "";
  width: 2vh;
  height: 2vh;
  left: -1.5em;
  position: absolute;
  /* left: -40%; */
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  @apply bg-primary rotate-90 top-1/2 transform -translate-y-1/2 shadow-lg transition-all duration-700;
}

@media (min-width: 1024px) {
  .nav-active::before {
    width: 1em;
    height: 1em;
  }
}


.shopify-buy__option-select-wrapper {
  display: none !important;
}
