/*! purgecss start ignore */
.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide > div {
  width: 100%;
}
.showcase .carousel.carousel-slider {
  position: static !important;
}
.showcase .slide.selected {
  transform: scale(1.2);
  z-index: 12312312;
}
.showcase .slide.selected > div {
  height: 60%;
}
.showcase .slide:not(.selected) {
  @apply -mb-10;
}
.videos .thumb {
  height: 125px;
}
.videos .thumbs-wrapper.axis-vertical {
  overflow: visible !important;
}
.videos .thumbs-wrapper.axis-vertical .control-arrow {
  display: none !important;
}
.videos .slide {
  justify-content: flex-start;
  height: 400px !important;
  margin-bottom: 2.5rem;
}
.videos .slide img {
  position: absolute;
}
.videos .slide > div {
  height: 100% !important;
}
.videos .carousel-slider {
  margin: 0 auto;
  width: auto !important;
  overflow: visible !important;
}
.videos .carousel .thumb.selected,
.videos .carousel .thumb {
  margin-right: 2.5rem;
}
.videos .carousel .thumb {
  border: 0 !important;
}
@media (min-width: 768px) {
  .videos .carousel-slider {
    width: 600px !important;
  }
}

/* Music Slider */
.carousel.carousel-slider {
  overflow: visible !important;
}

#music .carousel.carousel-slider,
#videos .carousel.carousel-slider {
  overflow: visible !important;
}

#music .control-dots,
#videos .control-dots {
  bottom: -15% !important;
}

/* Videos Slider */

@media (min-width: 768px) {
  .control-dots {
    /* display: none; */
  }
}
/*! purgecss end ignore */
